import { render } from "./afterSale.vue?vue&type=template&id=b597b512&scoped=true"
import script from "./afterSale.vue?vue&type=script&lang=js"
export * from "./afterSale.vue?vue&type=script&lang=js"

import "./afterSale.vue?vue&type=style&index=0&id=b597b512&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-b597b512"
/* hot reload */
if (module.hot) {
  script.__hmrId = "b597b512"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('b597b512', script)) {
    api.reload('b597b512', script)
  }
  
  module.hot.accept("./afterSale.vue?vue&type=template&id=b597b512&scoped=true", () => {
    api.rerender('b597b512', render)
  })

}

script.__file = "src/views/shop/order/afterSale/afterSale.vue"

export default script