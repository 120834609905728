<template>
  <div class="wrapper">
      <headBack>
        <template v-slot:title>
          <div class='title'>
             申请售后
          </div>
        </template>
      </headBack>
      <div class='header_cont'></div>
      <div class="list_box">
        <div class="xz">选择售后方式</div>
        <div class="btns">
          <button :class="{'green':isActive == item.modeId}" v-for="(item,index) in modeList"  @click=checkBtn(item.modeId)> {{ item.modeName }}</button>

        </div>
      </div>
    <div class="list_box">
      <div class="xz">选择售后商品</div>
      <div
          class="sp"
          v-for="(item,index) in orderData"
      >
        <div class="check"><van-checkbox v-model="checked" /></div>
        <van-card
            :num="item.goodsNum"
            :price="item.goodsPrice"
            desc=""
            :title="item.goodsName"
            :thumb="item.iconUrl"
        />
      </div>

    </div>
    <div class="list_box">
      <div class="xz">申请原因</div>
      <div>
        <van-dropdown-menu>
          <van-dropdown-item v-model="value1" :options="reasons"/>
        </van-dropdown-menu>
      </div>
      <div class="message">
        <van-field
            v-model="message"
            rows="2"
            autosize
            type="textarea"
            maxlength="120"
            placeholder="请输入原因"
            show-word-limit

        />
      </div>
      <div class="fileList">
        <van-uploader :after-read="afterReadImgs" upload-text='上传图片' v-model='fList' max-count='6' accept="image/*"/>
      </div>

    </div>
    <div class="list_box">
      <div class="xz">联系电话</div>
      <van-field v-model="tel" type="tel" placeholder="请输入手机号"/>
    </div>
    <van-button round type="success" @click="submitBtn">提交申请</van-button>
  </div>
</template>

<script>
import {setWxShareDate} from '@/libs/utils.js'
import { onMounted, ref, computed } from "vue"
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { Dialog ,Toast} from 'vant'
import homeApi from '@axios/home'
import shopApi from '@axios/shop'
export default {
  name: "afterSale",
  setup(){
    const route = useRoute();
    const router = useRouter();
    const isActive = ref(1)
    const activeName = ref('我要换货')
    const checked = ref(true)
    const value1 = ref('1');
    const fList = ref([]);
    const imgList = ref([]);
    const tel = ref('');
    const message = ref('');
    const orderData = JSON.parse(route.query.orderData)
    console.log(orderData)
    const option1 = [
      { text: '轻选择售后原因', value: 0 },
      { text: '新款商品', value: 1 },
      { text: '活动商品', value: 2 },
    ];

    const modeList = ref('');


    const getAfterSalesMode = () => {
      shopApi.afterSalesMode({}).then((res)=> {
        console.log(res)
        modeList.value = res.data;
      })
    }
    getAfterSalesMode()
    const checkBtn = (index)=> {
      isActive.value =index
      activeName.value = modeList.value.find(item => item.modeId ==index ).modeName
    }
    const  setData = (data) => {
      let arr =[];
      data.forEach((item,index)=> {
        const obj ={
          'text':item.reasonName,
          'value':item.reasonId
        }
        arr.push(obj)
      })
      return arr;
    }
    const reasons = ref([])
    const afterSalesReasons = ()=> {
      const params ={}
      shopApi.afterSalesReasons(params).then((res)=> {
        console.log(res)
        reasons.value = setData(res.data);
        console.log('sss', reasons.value)
      })
    }
    afterSalesReasons()
    const afterReadImgs = (file) => {
      console.log(file)

      let formData = new FormData();
      formData.append('file',file.file)

      homeApi.imgUpload(formData).then((res)=>{
        console.log(res)
        imgList.value.push(
            {
              picUrl:'https://file.yihu365.cn' + res.message
            }
        )
        console.log(imgList.value)
      })
    }


    const submitBtn = ()=> {
      if(!tel.value){
        Toast('请输入手机号')
        return;
      }
      let reasonName =  reasons.value.find(item => item.value ==value1.value ).text

      const params ={
        afterSaleModeId:isActive.value,
        afterSaleModeName:activeName.value,
        afterSaleReasonId:value1.value,
        afterSaleReasonName:reasonName,
        contentUrlList:imgList.value,
        content : message.value,
        orderId:route.query.orderId,
        mobile:tel.value
      }
      console.log(params)
      shopApi.afterSalesSubmit(params).then((res)=> {
        console.log(res)
        if(res.data){
          Toast("提交成功");
          router.push({name:'shopOrder'});
        }
      })
    }
    return {
      modeList,
      checked,
      value1,
      option1,
      getAfterSalesMode,
      isActive,
      checkBtn,
      orderData,
      afterSalesReasons,
      reasons,
      setData,
      afterReadImgs,
      imgList,
      fList,
      submitBtn,
      tel,
      message

    }
  }

}
</script>

<style scoped lang="scss">
.wrapper{
  color:#323232;
  min-height: 100vh;
  background:  #f7f7f7;
  padding-bottom: 50px;
}

   .list_box {

     box-sizing: border-box;
     font-size: 30px;
     width: 94%;
     border-radius: 16px;
     margin:30px auto;
     background: #FFFFFF;
     padding:20px;
     .xz{
       font-size: 32px;
       margin-bottom: 24px;
     }
     .btns{
       display: flex;
       flex-direction: column;
     }
     .green{
       background: #00c291;
       color:#fff;
     }
     button{
        color:#666666;
        margin-bottom: 28px;
        border:none;
        border-radius: 30px;
        line-height: 58px;
     }
     .sp{
       background: #fafafa;
       display: flex;
       align-items: center;
       border-radius: 16px;
       .check{
         margin-left :20px;
       }
     }
     ::v-deep(.van-checkbox__icon--checked .van-icon){
        border-color:#00c291;
         background: #00c291;

     }
   }
  ::v-deep(.van-dropdown-menu__bar){
    box-shadow: none;
    border:1px solid #f3f3f3;
    height:60px;
    border-radius: 8px;
  }
  ::v-deep(.van-dropdown-menu__title){
    font-size: 28px;
  }

  ::v-deep(.van-dropdown-menu__title--active){
    color:#00c291;
  }
  ::v-deep(.van-dropdown-item__option--active){
    color:#00c291;
  }
::v-deep(.van-dropdown-item__option--active .van-dropdown-item__icon){
  color:#00c291;
}
::v-deep(.van-cell){
  background: #f9f9f9;
}
.message{
  margin:26px 0;
}
::v-deep(.van-button){
  display: block;
  margin: 40px auto;
  width: 700px;
  background: #00c291;
}
.fileList{
  ::v-deep(.van-uploader__upload){
    margin-top:0;
  }
}
</style>
